import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><undefined parentName="p">{`
                `}<div {...{
          "className": "left center horizontal"
        }}>{`
                  `}<img parentName="div" {...{
            "src": "/static/8ecb3a809f4e42fe31e9e90ec53bcde0/f7616/Morne_Rysha_tan_mei_is_in_her_late_thirties_she_is_a_water_gena_bf57d5e6-5f8d-426f-b80b-c0d15721291e.png",
            "srcSet": ["/static/8ecb3a809f4e42fe31e9e90ec53bcde0/5ff7e/Morne_Rysha_tan_mei_is_in_her_late_thirties_she_is_a_water_gena_bf57d5e6-5f8d-426f-b80b-c0d15721291e.png 375w", "/static/8ecb3a809f4e42fe31e9e90ec53bcde0/1d69c/Morne_Rysha_tan_mei_is_in_her_late_thirties_she_is_a_water_gena_bf57d5e6-5f8d-426f-b80b-c0d15721291e.png 750w", "/static/8ecb3a809f4e42fe31e9e90ec53bcde0/f7616/Morne_Rysha_tan_mei_is_in_her_late_thirties_she_is_a_water_gena_bf57d5e6-5f8d-426f-b80b-c0d15721291e.png 766w"],
            "width": "400px",
            "className": "left center horizontal"
          }}></img>{`
                  `}<div parentName="div" {...{
            "className": "caption"
          }}>{`Rysha Tan Mei`}</div>{`
                `}</div>{`
                `}</undefined></p>
    <p>{`Rysha Tan Mei was a student at `}<a parentName="p" {...{
        "href": "/The%20Basentia%20Omnia",
        "title": "The Basentia Omnia"
      }}>{`The Basentia`}</a>{` at the same time that `}<a parentName="p" {...{
        "href": "/Rikkart%20Lauten",
        "title": "Rikkart Lauten"
      }}>{`Rikkart Lauten`}</a>{` was. Rysha was born an `}<a parentName="p" {...{
        "href": "/Elemental%20Scion",
        "title": "Elemental Scion"
      }}>{`Elemental Scion`}</a>{` of the `}<a parentName="p" {...{
        "href": "/Primal%20Spirit%20of%20Water",
        "title": "Primal Spirit of Water"
      }}>{`Primal Water Spirit`}</a>{`. She was born to a wealthy, aristocratic family in `}<a parentName="p" {...{
        "href": "/Tharsis",
        "title": "Tharsis"
      }}>{`Tharsis`}</a>{`. When she came of age, she was sent to `}<a parentName="p" {...{
        "href": "/Aljiuedum",
        "title": "Aljiuedum"
      }}>{`Aljiuedum`}</a>{` to attend University at `}<a parentName="p" {...{
        "href": "/The%20Basentia%20Omnia",
        "title": "The Basentia Omnia"
      }}>{`The Basentia`}</a>{` to become an `}<a parentName="p" {...{
        "href": "/Acronist",
        "title": "Acronist"
      }}>{`Acronist`}</a>{` per family tradition.`}</p>
    <h2>{`Ubringing`}</h2>
    <p>{`Rysha is a calculating and careful, but compassionate. Her upbringing in the aristocracy of `}<a parentName="p" {...{
        "href": "/Brava",
        "title": "Brava"
      }}>{`Brava`}</a>{` taught her the ways of leadership and how to treat people of all stations---with respect. Before coming to the Basentia, she was taught a variety of subjects by royal scholars such as Maths, Sciences, Philosphies, and more, though she favored Science the most.`}</p>
    <h2>{`The Basentia`}</h2>
    <p>{`At the Basentia, she struggled with trying to fit in---many of the students were very different to her---and not because of her blue skin and hair. She could not stand the arrogance that many of the wealthier students had. They, like her, were destined to become leaders within their community, but they treated the notion with a disrespect that disgusted her.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      